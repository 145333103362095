<template>
	<div id="Contact">
		<Header :title="$t('contact.header.title')" :description="$t('contact.header.description')" />
		<div class="container mx-auto px-5 sm:px-8 md:px-16 lg:px-28 py-16">
			<h2 class="text-footerBg text-3xl mb-5 mt-20">
				<span>{{ $t('contact.h1') }}</span>
				<span class="bg-info w-16 my-2 h-1 rounded-lg block"></span>
			</h2>
			<form class="mx-auto py-5 px-5 border-2 border-readmoreBG my-12" @submit.prevent="submit">
				<div class="w-full lg:w-3/4 mx-auto my-8">
					<input
						type="text"
						id="name"
						v-model="form.name"
						@blur="$v.form.name.$touch()"
						name="name"
						class="border py-3 px-5 w-full block"
						:placeholder="$t('contact.form.name')"
						:class="$v.form.name.$error ? ' border-red-600' : 'border-searchBorder'"
					/>
					<p class="my-1 text-red-600 font-medium tracking-wide leading-relaxed" v-if="$v.form.name.$error">
						{{ $t('contact.nameRequired') }}
					</p>
				</div>
				<div class="w-full lg:w-3/4 mx-auto my-8">
					<input
						type="email"
						id="email"
						v-model="form.email"
						@blur="$v.form.email.$touch()"
						name="email"
						class="border py-3 px-5 w-full block"
						:placeholder="$t('contact.form.email')"
						:class="$v.form.email.$error ? ' border-red-600' : 'border-searchBorder'"
					/>
					<p class="my-1 text-red-600 font-medium tracking-wide leading-relaxed" v-if="$v.form.email.$error">
						{{ $t('contact.emailRequired') }}
					</p>
				</div>
				<div class="w-full lg:w-3/4 mx-auto my-8">
					<textarea
						name="message"
						id="message"
						v-model="form.message"
						@blur="$v.form.message.$touch()"
						:placeholder="$t('contact.form.message')"
						class="border py-3 px-5 w-full block h-56"
						:class="$v.form.message.$error ? ' border-red-600' : 'border-searchBorder'"
					></textarea>
					<p
						class="my-1 text-red-600 font-medium tracking-wide leading-relaxed"
						v-if="$v.form.message.$error"
					>
						{{ $t('contact.messageRequired') }}
					</p>
				</div>
				<div class="w-full lg:w-3/4 mx-auto my-8 flex justify-center flex-wrap">
					<vue-recaptcha
						sitekey="6Lc1M6QaAAAAAOrb2DtlCu0vTu-NLlJQe3P96-Cx"
						:loadRecaptchaScript="true"
						ref="recaptcha"
						@verify="onVerify"
						class="mx-auto"
					></vue-recaptcha>
					<p
						v-if="!notRobot && confirmRobot"
						@click="confirmRobot = ''"
						class="text-red-600 border-red-200 border bg-red-50 rounded-md py-2 mt-3 w-full flex justify-center font-medium cursor-pointer"
					>
						{{ confirmRobot }}
					</p>
				</div>
				<button
					@click.prevent="submit"
					class="bg-btn focus:outline-none text-white border border-btn py-3 px-5 w-full lg:w-3/4 mx-auto block my-8"
				>
					<span v-if="!loading"> {{ $t('contact.submit') }}</span>
					<i v-else class="bx bx-loader-circle bx-spin text-lg text-white"></i>
				</button>
				<p
					v-if="success"
					:class="$i18n.locale == 'EN' ? 'flex-row-reverse' : null"
					class="bg-green-700 flex items-center justify-center text-white w-full rounded-md py-3 text-lg font-medium tracking-wide leading-relaxed"
				>
					<span>{{ success }}</span>
					<i class="bx bx-check-double mx-2 text-2xl"></i>
				</p>
				<p
					v-if="errMsg"
					:class="$i18n.locale == 'EN' ? 'flex-row-reverse' : null"
					class="bg-red-600 flex items-center justify-center text-white w-full rounded-md py-3 text-lg font-medium tracking-wide leading-relaxed"
				>
					<span>{{ errMsg }}</span>
					<i class="bx bx-error-circle mx-2 text-2xl"></i>
				</p>
			</form>

			<div class="height relative w-full border border-info overflow-hidden">
				<div id="mainBaranch" class="w-full h-full absolute"></div>
				<div
					class="absolute w-full h-1/6 noneBlurTilte z-50 bottom-0 flex items-center justify-center text-center"
					style="background: rgba(0, 92, 150, 0.8)"
				>
					<h2 class="text-white font-medium text-lg">
						{{ $t('contact.mainBranch') }}
					</h2>
				</div>
			</div>

			<div
				class="w-full md:w-1/2 mx-auto flex items-center justify-between md:justify-around mt-20 px-8 sm:px-14 md:px-0"
			>
				<div class="text-center">
					<a
					rel="noreferrer"
						href="mailto:info@llms.ly"
						class="bg-contactIcon h-16 w-16 cursor-pointer flex items-center justify-center rounded-full mx-auto"
					>
						<img alt="" class="object-cover" loading="lazy" src="../assets/mail.svg" />
					</a>
					<a rel="noreferrer" href="mailto:info@llms.ly" class="block text-lg text-footerBg font-medium mt-3">info@llms.ly</a>
				</div>
				<div class="text-center">
					<a
						rel="noreferrer"
						href="tel:0945304779"
						class="bg-contactIcon cursor-pointer h-16 w-16 flex items-center justify-center rounded-full mx-auto"
					>
						<img class="object-cover" loading="lazy" alt="" src="../assets/phone.svg" />
					</a>
					<a rel="noreferrer" href="tel:0945304779" class="block text-lg text-footerBg font-medium mt-3">0945304779</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Header from "@/components/layout/Header";
import { required, email } from "vuelidate/lib/validators";
import mapboxgl from "mapbox-gl";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Contact",
  mounted() {
    const mainBaranch = new mapboxgl.Map({
      container: "mainBaranch", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: [13.180161, 32.875353], // starting position [lng, lat]
      zoom: 10, // starting zoom
    });
    mainBaranch.scrollZoom.disable();
    const el = document.createElement("div"); // create html element as a marker
    el.className = "mainBaranchMarker";
    mainBaranch.on("load", () => {
      new mapboxgl.Marker(el)
        .setLngLat([13.138538942209182, 32.86684281564928])
        .addTo(mainBaranch);
    });
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
      notRobot: false,
      confirmRobot: "",
      success: "",
      errMsg: "",
    };
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      message: { required },
    },
  },
  methods: {
    onVerify() {
      this.notRobot = true;
      this.confirmRobot = null;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      if (this.notRobot) {
        this.loading = true;
        this.$axios
          .post("/api/contactUs", this.form)
          .then(({ data }) => {
            this.loading = false;
            if (data.message == "تم الارسال بنجاح") {
              this.success = this.$t("contact.successMsg");
              this.confirmRobot = null;
              this.errMsg = null;
              this.form = {
                name: "",
                email: "",
                message: "",
              };
              this.$v.form.$reset();
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response) {
              this.errMsg = this.$t("contact.errMsg");
              this.$v.form.$reset();
            }
          });
      } else {
        this.confirmRobot = this.$t("contact.robotConfirm");
        this.$v.form.$reset();
      }
    },
  },
  components: {
    Header,
    VueRecaptcha,
  },
};
</script>
<style scoped lang="scss">
.height {
  height: 70vh;
  @media only screen and (max-width: 550px) {
    height: 50vh;
  }
}
</style>
